<template>
    <main>
        <form>
            <div class="form-row">
                <div class="form-group col-md-12">
                    <label >ANALISIS:</label>
                    <textarea type="text" v-model="analisis" class="form-control" rows="8" :class="{'is-invalid':$v.analisis.$error && $v.analisis.$dirty}"></textarea>
                </div>
            </div>
        </form>
    </main>
</template>
<script>
import {required} from "vuelidate/lib/validators";

import urgenciaAnalisisService from "../../../../services/urgenciaAnalisisService";
import Toast from '../../../../components/common/utilities/toast';
import {isEmpty} from 'lodash';

export default {
    props:['idHistoria','finalizada'],
    data () {
        return {
            id_historia:'',
            id_urgencia_analisis:'',
            analisis:'',
            urgenciaAnalisis:{},
        }
    },
    validations (){
        return {
            analisis:{required},
        }
    },
    methods:{
        async save(){
            try {
                this.$v.$touch();

                if(this.$v.$invalid){
                    return 
                }

                this.urgenciaAnalisis.id_historia=this.idHistoria;
                this.urgenciaAnalisis.analisis=this.analisis;

                this.LoaderSpinnerShow();
                if(this.id_urgencia_analisis!==''){
                    this.urgenciaAnalisis.id=this.id_urgencia_analisis;
                    await urgenciaAnalisisService.update(this.urgenciaAnalisis);
                }else{
                    await urgenciaAnalisisService.store(this.urgenciaAnalisis);
                    const response=await urgenciaAnalisisService.showByHc(this.idHistoria);
                    this.id_urgencia_analisis=response.data.id;
                }
                
                
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'success',
                    title: 'Datos guardados con exito'
                });
            } catch (error) {
                console.error(error);
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }
        },
       
    },
    watch:{
        finalizada: async function (value){

            if(value==='N'){
             const response=await urgenciaAnalisisService.showByHc(this.idHistoria);
             if(!isEmpty(response.data)){
                this.analisis=response.data.analisis;
                this.id_urgencia_analisis=response.data.id;
             }
             
            } 
        }
    },
    async created(){
        if(this.finalizada==='N'){
            const response=await urgenciaAnalisisService.showByHc(this.idHistoria);
            if(!isEmpty(response.data)){
                this.analisis=response.data.analisis;
                this.id_urgencia_analisis=response.data.id;
             }
        }
    }
    
}
</script>

