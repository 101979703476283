<template>
    <main>
        <form>
            <div class="form-row">
                <div class="form-group col-md-12">
                    <label >CONDUCTA:</label>
                    <textarea type="text" v-model="conducta" class="form-control" rows="8" :class="{'is-invalid':$v.conducta.$error && $v.conducta.$dirty}"></textarea>
                </div>
            </div>
        </form>
    </main>
</template>
<script>
import {required} from "vuelidate/lib/validators";

import urgenciaConductaService from "../../../../services/urgenciaConductaService";
import Toast from '../../../../components/common/utilities/toast';
import {isEmpty} from 'lodash';

export default {
    props:['idHistoria','finalizada'],
    data () {
        return {
            id_historia:'',
            id_urgencia_conducta:'',
            conducta:'',
            urgenciaConducta:{},
        }
    },
    validations (){
        return {
            conducta:{required},
        }
    },
    methods:{
        async save(){
            try{
                this.$v.$touch();

                if(this.$v.$invalid){
                    return 
                }

                this.urgenciaConducta.id_historia=this.idHistoria;
                this.urgenciaConducta.conducta=this.conducta;

                this.LoaderSpinnerShow();
                
                if(this.id_urgencia_conducta!==''){
                    this.urgenciaConducta.id=this.id_urgencia_conducta;
                    await urgenciaConductaService.update(this.urgenciaConducta);
                }else{
                    await urgenciaConductaService.store(this.urgenciaConducta);
                    const response=await urgenciaConductaService.showByHc(this.idHistoria);
                    this.id_urgencia_conducta=response.data.id;
                }
                
                this.LoaderSpinnerHide();

                Toast.fire({
                    icon: 'success',
                    title: 'Datos guardados con exito'
                });
            } catch (error) {
                console.error(error);
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }
        },
       
    },
    watch:{
        finalizada: async function (value){

            if(value==='N'){
             const response=await urgenciaConductaService.showByHc(this.idHistoria);
             if(!isEmpty(response.data)){
                this.conducta=response.data.conducta;
                this.id_urgencia_conducta=response.data.id;
             }
             
            } 
        }
    },
    async created(){
        if(this.finalizada==='N'){
            const response=await urgenciaConductaService.showByHc(this.idHistoria);
            if(!isEmpty(response.data)){
                this.conducta=response.data.conducta;
                this.id_urgencia_conducta=response.data.id;
             }
        }
    }
    
}
</script>

