<template>
    <main>
        <form>
            <div class="form-row">
                <div class="form-group col-md-12">
                    <label >REVISION POR SISTEMAS:</label>
                    <textarea type="text" v-model="revision" class="form-control" rows="8" :class="{'is-invalid':$v.revision.$error && $v.revision.$dirty}"></textarea>
                </div>
            </div>
        </form>
    </main>
</template>
<script>
import {required} from "vuelidate/lib/validators";

import urgenciaRevisionService from "../../../../services/urgenciaRevisionService";
import {isEmpty} from 'lodash';
import Toast from '../../../../components/common/utilities/toast';

export default {
    props:['idHistoria','finalizada'],
    data () {
        return {
            id_historia:'',
            id_urgencia_revision:'',
            revision:'',
            urgenciaRevision:{},
        }
    },
    validations (){
        return {
            revision:{required},
        }
    },
    methods:{
        async save(){
            try {
                this.$v.$touch();

                if(this.$v.$invalid){
                    return 
                }

                this.urgenciaRevision.id_historia=this.idHistoria;
                this.urgenciaRevision.revision=this.revision;

                this.LoaderSpinnerShow();

                if(this.id_urgencia_revision!==''){
                    this.urgenciaRevision.id=this.id_urgencia_revision;
                    await urgenciaRevisionService.update(this.urgenciaRevision);
                }else{
                    await urgenciaRevisionService.store(this.urgenciaRevision);
                    const response=await urgenciaRevisionService.showByHc(this.idHistoria);
                    this.id_urgencia_revision=response.data.id;
                }
                
                
                this.LoaderSpinnerHide();

                Toast.fire({
                    icon: 'success',
                    title: 'Datos guardados con exito'
                });
                
            } catch (error) {
                console.error(error);
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }
        },
       
    },
    watch:{
        finalizada: async function (value){

            if(value==='N'){
             const response=await urgenciaRevisionService.showByHc(this.idHistoria);
             if(!isEmpty(response.data)){
                this.revision=response.data.revision;
                this.id_urgencia_revision=response.data.id;
             }
             
            } 
        }
    },
    async created(){
        if(this.finalizada==='N'){
            const response=await urgenciaRevisionService.showByHc(this.idHistoria);
            if(!isEmpty(response.data)){
                this.revision=response.data.revision;
                this.id_urgencia_revision=response.data.id;
             }
        }
    }
    
}
</script>

